import { useEffect, useMemo, useState } from 'react';
import MenuIcon from '../../components/icons/menu';
import { t } from '../../stores/i18n.ts';
import { useLockScroll } from '../../utils';
import { Context } from '../../utils/context.ts';
import WithApp from '../../utils/withApp.tsx';
import { useIsLoggedIn, useLogout } from '../../utils/hooks.tsx';
import ChevronDown from '../../components/icons/chevronDown.tsx';

type Props = {
  path: string;
  context: Context;
};

function Component({ context, path }: Props) {
  const [open, setOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(path);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const isLoggedIn = useIsLoggedIn(context);
  const logout = useLogout();

  const navItems: Array<{
    key: string;
    url?: string;
    onClick?: () => void;
    children?: Array<{ key: string; url: string }>;
  }> = useMemo(() => {
    const baseItems = [
      { key: 'home', url: '/' },
      { key: 'blog', url: '/blog' },
      {
        key: 'workWithUs',
        children: [
          { key: 'offer', url: '/offer' },
          { key: 'createClub', url: '/create-club' },
          { key: 'pricing', url: '/pricing' },
        ],
      },
      {
        key: 'contact',
        children: [
          {
            key: 'about',
            url: '/about',
          },
          {
            key: 'contact',
            url: '/contact',
          },
        ],
      },
      { key: 'agilityPageTitle', url: '/agility-competitions' },
    ];

    if (isLoggedIn) {
      return [...baseItems, { key: 'logout', url: '/logout', onClick: logout }];
    }

    return baseItems;
  }, [isLoggedIn, logout]);

  useLockScroll(open);

  useEffect(() => {
    const callback = function () {
      setCurrentPath('');
    };

    document.getElementById('home-link')?.addEventListener('click', callback);

    return () => {
      document.getElementById('home-link')?.removeEventListener('click', callback);
    };
  }, []);

  const toggleExpandItem = (key: string) => {
    setExpandedItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <>
      <div className="w-full items-center hidden xl:flex">
        {navItems.map(item => {
          const baseClass =
            'relative group text-center rounded-xl py-2 px-4 no-underline hover:bg-slate-200 mx-2 text-[rgba(25,25,27,0.75)] hover:text-[var(--theme-primary)]';

          if (item.onClick) {
            return (
              <button key={item.key} type="button" onClick={item.onClick} className={baseClass}>
                {t(`nav_${item.key}`, context.locale)}
              </button>
            );
          }

          return (
            <div key={item.key} className="relative group">
              <a
                onClick={() => {
                  setCurrentPath(item.url || '');
                  setOpen(false);
                }}
                href={item.url || '#'}
                className={`${baseClass} ${item.url === currentPath || item.children?.find(c => c.url === currentPath) ? 'bg-slate-200' : ''}`}>
                <span>{t(`nav_${item.key}`, context.locale)}</span>
                {item.children && <ChevronDown fullClass="inline-block ml-2 w-4 h-4" />}
              </a>

              {item.children && (
                <div className="absolute left-2 top-full hidden group-hover:block bg-white shadow-lg rounded-md mt-2 min-w-32">
                  {item.children.map(subItem => (
                    <a
                      key={subItem.key}
                      href={subItem.url}
                      className="block px-4 py-2 whitespace-nowrap hover:bg-slate-200">
                      {t(`nav_${subItem.key}`, context.locale)}
                    </a>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <a href="/download" className="btn-action">
        {t('nav_download', context.locale)}
      </a>

      <span
        className="block cursor-pointer rounded-full bg-white px-5 py-2 hover:bg-slate-200 xl:hidden right-4 top-8"
        onClick={() => setOpen(true)}>
        <MenuIcon />
      </span>

      <div
        onClick={() => setOpen(false)}
        className={`${
          open ? 'visible opacity-40' : 'invisible opacity-0'
        } z-40 fixed top-0 left-0 h-screen w-screen bg-black duration-100 transition-all`}
      />

      <div
        className={`z-40 fixed top-0 h-screen w-[240px] bg-white duration-200 ${
          open ? 'right-0 ' : 'right-[-250px]'
        }`}>
        <div className="flex h-full flex-col items-start overflow-auto">
          <div className="flex w-full flex-col items-center space-y-5 px-6 pt-20">
            {navItems.map(item => {
              const matches =
                item.url === currentPath ||
                (item.url === '/' && ['/', ''].includes(currentPath)) ||
                item.children?.find(c => c.url === currentPath);

              const baseClass =
                'w-full text-left rounded-xl py-2 px-4 no-underline hover:bg-slate-200 text-[rgba(25,25,27,0.75)] hover:text-[var(--theme-primary)] ';

              if (item.onClick) {
                return (
                  <button key={item.key} type="button" onClick={item.onClick} className={baseClass}>
                    {t(`nav_${item.key}`, context.locale)}
                  </button>
                );
              }

              return (
                <div key={item.key} className="w-full">
                  <a
                    onClick={() => {
                      if (item.children) {
                        toggleExpandItem(item.key);
                      } else {
                        setCurrentPath(item.url || '');
                        setOpen(false);
                      }
                    }}
                    href={item.url || '#'}
                    className={`${baseClass} flex justify-between items-center ${
                      matches ? 'bg-slate-200' : ''
                    }`}>
                    <span>{t(`nav_${item.key}`, context.locale)}</span>
                    {item.children && (
                      <span className="ml-2">{expandedItems[item.key] ? '-' : '+'}</span>
                    )}
                  </a>

                  {item.children && expandedItems[item.key] && (
                    <div className="ml-4 mt-2 space-y-2">
                      {item.children.map(subItem => (
                        <a
                          key={subItem.key}
                          href={subItem.url}
                          className="block px-4 py-2 text-[rgba(25,25,27,0.75)] hover:bg-slate-200">
                          {t(`nav_${subItem.key}`, context.locale)}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default function Navigation({ path, context }: Props & { context: Context }) {
  return (
    <WithApp context={context}>
      <Component context={context} path={path} />
    </WithApp>
  );
}
